@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&display=swap);

/* =========== google fonts =========== */

/* ======== base style ======= */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  background: #0a071a;
}

section,
footer {
  padding: 70px 0px;
}

h1,
h2 {
  font-size: 2rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #f5b70a;
}

p {
  font-size: 1rem;
  color: #c4c4c4;
  line-height: 30px;
  font-family: "Rubik", sans-serif;
}

a {
  text-decoration: none;
  color: unset;
}

i {
  color: #fff;
}
/*====================make it responsive =================*/
@media only screen and (max-width: 767px) {
  section {
    padding: 40px 0px;
  }
}

.header {
  width: 100%;
  height: 80px;
  line-height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  background: #141124;
}
.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo h2 {
  margin-bottom: 0;
}

.nav__list {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  list-style: none;
  grid-column-gap: 2.7rem;
  -webkit-column-gap: 2.7rem;
          column-gap: 2.7rem;
}
.nav__item a {
  color: #fff;
  cursor: pointer;
  transition: 0.3s;
}
.nav__item a:hover {
  color: #f5b70a;
}

.custom__search {
  display: flex;
  align-items: center;
  padding: 10px 25px;
  height: 35px;
  border-radius: 50px;
  background: #0a071a;
  margin-top: 22px;
}
.custom__search input {
  border: none;
  outline: none;
  background: transparent;
  color: #fff;
}
.custom__search span i {
  cursor: pointer;
}
.mobile__menu span i {
  font-size: 1.3rem;
}
.mobile__menu {
  display: none;
}
.cart__icon {
  position: relative;
  z-index: 999;
  width: 40px;
  height: 30px;
  border-radius: 5px;
  background: #f76e11;
  display: flex;
  align-items: center;
  justify-content: center;
}
.badge {
  position: absolute;
  top: -2px;
  right: -3px;
  z-index: 1000;
  color: #141124;
  font-size: 0.8rem;
  font-weight: 700;
}

/*====================make it responsive =================*/
@media only screen and (max-width: 992px) {
  .nav__menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: #0a071a7e;
    display: none;
  }
  .nav__list__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    z-index: 100;
    background: #141124;
    flex-direction: column;
    box-shadow: 5px 5px 15px -5px #f5b70a;
    justify-content: center !important;
    padding: 0 20px;
  }
  .nav__list {
    flex-direction: column;
    padding: 0;
  }
  .nav__item a {
    font-size: 0.9rem;
  }
  .header {
    line-height: 40px;
    padding: 10px 0px;
    height: 60px;
  }
  .custom__search {
    border: 1px solid #c4c4c425;
    padding: 5px 15px;
    font-size: 0.9rem;
    height: 30px;
    margin-top: 15px;
  }
  .mobile__menu {
    display: block;
  }
  .logo h2 {
    font-size: 1.5rem;
  }
  .active__menu {
    display: block;
  }
}

.btn {
  padding: 7px 25px;
  border-radius: 5px;
  background: #f76e11;
  color: #fff;
  font-size: 0.9rem;
  margin-top: 30px;
  transition: 0.5s;
}
.btn:hover {
  background: transparent;
  color: #fff;
  border: 1px solid #f76e11;
}

/*====================make it responsive =================*/
@media only screen and (max-width: 992px) {
  .slider__content h2 {
    font-size: 1.5rem;
  }
  .slider__content p {
    font-size: 0.8rem;
  }
  .slider__content .btn {
    padding: 5px 20px;
    font-size: 0.7rem;
  }
}
@media only screen and (max-width: 768px) {
  .slider__content {
    padding-left: 0;
  }
  .slick-prev,
  .slick-next {
    display: none !important;
  }
}

.single__product {
  background: #141124;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.8s;
  -webkit-animation: animate 1.5s ease-in;
          animation: animate 1.5s ease-in;
}
.single__product:hover {
  box-shadow: 5px 5px 15px -5px #f5b70a;
}
.product__content {
  padding: 0px 20px;
  padding-bottom: 15px;
}
.product__content h6 {
  color: #f5b70a;
  text-align: center;
}
.rating span i {
  color: #f76e11;
}
.rating {
  margin-bottom: 10px;
}
.price {
  color: #fff;
}
.price span {
  color: #f5b70a;
  font-size: 1.3rem;
  font-weight: 500;
}
.shopping__icon {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: #f76e11;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
/*====================make it animate =================*/
@-webkit-keyframes animate {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes animate {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
/*====================make it responsive =================*/
@media only screen and (max-width: 767px) {
  .product__content h6 {
    font-size: 0.9rem;
  }
  .price {
    font-size: 0.8rem;
  }
  .price span {
    font-size: 0.9rem;
  }
}

.single__feature span {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #ff766b;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
  transition: 1s;
}
.single__feature span:hover {
  background: #ff766b;
  border: none;
}
.single__feature span i {
  font-size: 1.5rem;
}
.choose__content h5 {
  color: #fff;
  margin-bottom: 15px;
}
.single__feature h6 {
  color: #f5b70a;
  margin-bottom: 0;
}
.single__feature p {
  font-size: 0.7rem;
}
.feature__icon-two {
  border: 1px solid #2ace92 !important;
  background: transparent !important ;
}
.feature__icon-two:hover {
  border: none !important;
  background: #2ace92 !important;
}
.feature__icon-three {
  border: 1px solid #f5b70a !important;
  background: transparent !important;
}
.feature__icon-three:hover {
  border: none !important;
  background: #f5b70a !important;
}
.feature__icon-four {
  border: 1px solid #7865ff !important;
  background: transparent !important;
}
.feature__icon-four:hover {
  border: none !important;
  background: #7865ff !important;
}
/*====================make it responsive =================*/
@media only screen and (max-width: 992px) {
  .choose__content h2 {
    font-size: 1.5rem;
  }
  .choose__content h5 {
    font-size: 1rem;
  }
  .choose__content p {
    font-size: 0.8rem;
  }
  .single__feature h6 {
    font-size: 0.8rem;
  }
  .single__feature p {
    font-size: 0.6rem;
  }
}

.filter-btn {
  border: 1px solid #f76e11;
  padding: 7px 25px;
  margin-left: 15px;
  color: #fff;
  background: transparent;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.8s;
}
.filter-btn:hover {
  background: #f76e11;
  border: 1px solid #fff;
}

.menu__title {
  color: #f5b70a;
}
.active__btn {
  background: #f76e11;
}
/*====================make it responsive =================*/
@media only screen and (max-width: 992px) {
  .menu__title {
    font-size: 1.4rem;
  }
  .filter-btn {
    padding: 5px 20px;
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 767px) {
  .filter-btn {
    margin-bottom: 15px;
  }
}

.single__testimonial h6 {
  color: #f5b70a;
  font-size: 1.1rem;
}
.single__testimonial p {
  font-size: 0.8rem;
  color: #fff;
}
/*====================make it responsive =================*/
@media only screen and (max-width: 992px) {
  .slider__content h2 {
    font-size: 1.32rem;
    margin-bottom: 20px !important;
  }
  .single__testimonial p {
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 768px) {
  .slider__wrapper {
    flex-direction: column;
    width: 100%;
  }
  .slider__content {
    width: 100% !important;
  }
  .slider__img {
    display: none;
  }
}

.app__container {
  background: #f5b70a;
  border-radius: 10px;
}
.app__img {
  margin-bottom: -100px;
}
.app__content h5 {
  color: #0a071a;
}
.app__content h2 {
  color: #0a071a;
  font-weight: 600;
}
.app__content {
  padding-top: 50px;
}
.app__content p {
  color: #fff;
}
.btn__apple {
  background: #0a071a;
  border: none;
  color: #fff;
  padding: 7px 25px;
  border-radius: 5px;
}

.btn__google {
  background: #fff;
  color: #0a071a;
  border: none;
  padding: 7px 25px;
  border-radius: 5px;
}
.btn__google i {
  color: #0a071a;
}
.btn__apple:hover a {
  color: #fff;
}
.btn__google:hover a {
  color: #0a071a;
}
/*====================make it responsive =================*/
@media only screen and (max-width: 992px) {
  .app__img img {
    width: 100%;
  }
  .app__content h5 {
    font-size: 1rem;
  }
  .app__content h2 {
    font-size: 1.5rem;
  }
  .app__content p {
    font-size: 0.7rem;
    line-height: 25px;
  }
  .btn__apple,
  .btn__google {
    padding: 5px 20px;
    font-size: 0.8rem !important;
  }
  .app__content {
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 768px) {
  .app__content {
    padding: 30px;
  }
  .app__img {
    margin-bottom: 0px;
  }
}

.footer {
  padding-bottom: 0;
}
.footer__link-title {
  color: #fff;
}
.link__item {
  color: #c4c4c4;
  padding-left: 0;
  background: transparent;
}
.link__item a {
  color: #c4c4c4;
}
.footer__bottom {
  background: #141124;
  padding: 10px 0px;
  text-align: center;
  margin-top: 50px;
}
.footer__bottom p {
  font-size: 0.9rem;
  margin-bottom: 0;
}
/*====================make it responsive =================*/

@media only screen and (max-width: 992px) {
  .link__item a {
    font-size: 0.8rem;
  }
  .link__item {
    font-size: 0.8rem;
  }
  .logo p {
    font-size: 0.8rem;
  }
  .footer__bottom p {
    font-size: 0.7rem;
  }
  .footer__bottom {
    padding: 15px 0px;
  }
}

